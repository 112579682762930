body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.invoice table tbody td .label {
  display: none;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-iframe {
  width: 100%;
  height: 500px;
  border: none;
}


@media (max-width: 600px) {
  .invoice table {
      width: 100%;
  }
  .invoice table thead {
      display: none;
  }
  .invoice table tbody tr {
      display: block;
      margin-bottom: 20px;
  }
  .invoice table tbody td {
      display: block;
      width: 100%;
  }
  .invoice table tbody td .label {
      display: block;
      font-size: 12px;
      margin-bottom: 5px;
      margin-top: 5px;
  }
}